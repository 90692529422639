import { useAuthStore } from "~~/store/auth";

export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore();
  if (typeof window != "undefined" && window && window?.intercomSettings)
    window?.Intercom?.("update", window.intercomSettings);
  if (authStore.accountLevelString == "disabled") {
    setTimeout(() => {
      if (typeof window !== "undefined" && window) {
        let roleID = localStorage.getItem("user_role_id");
        if (roleID == "-1" || roleID == "17") {
          navigateTo("/disabled");
        }
      }
    }, 500);
  }
  if (!useCookie("token").value) {
    setTimeout(() => {
      if (typeof window !== "undefined" && window) {
        navigateTo(`/login`);
        localStorage.setItem("currentPath", window.location.href);
      }
    }, 500);
  }
});
